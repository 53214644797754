import React, { useState, useRef, useEffect } from 'react';
import OpenClose from './accordion/open-close';

//
// import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types";
// import { renderRichText } from "gatsby-source-contentful/rich-text";

// const options = {
//   renderMark: {
//     [MARKS.BOLD]: text => <span className={"font-bold"}>{text}</span>,
//   },

//   renderNode: {
//     [BLOCKS.PARAGRAPH]: (node, children) => <p className="">{children}</p>,
//     [INLINES.HYPERLINK]: (node, children) => {
//       return (
//         <a
//           className={
//             "text-accent-green hover:underline"
//           }
//           href={node.data.uri}
//         >
//           {" "}
//           {node.content[0].value}{" "}
//         </a>
//       )
//     }
//   }
// }

const ShowMoreItem = ({ title, isOpen, children }) => {
  const [isActive, setActive] = useState(isOpen);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', function () {
      if (ref.current) {
        setHeight(ref.current.offsetHeight);
      }
    });
    return () => {
      window.removeEventListener('resize', function () {
        if (ref.current) {
          setHeight(ref.current.offsetHeight);
        }
      });
    };
  });

  return (
    <div className="rounded-8 -mb-px">
      <div
        className="relative w-[110px] cursor-pointer pr-6 text-base"
        onClick={() => setActive(!isActive)}
      >
        <div className={`svg-wrap absolute right-0 top-1.5 h-3 w-3`}>
          <OpenClose isActive={isActive} color={``} />
        </div>
        {!isActive ? title : 'Show less'}
      </div>
      <div
        className={`transition-height overflow-hidden duration-500 ease-in-out`}
        style={{
          height: `${isActive ? height : 0}px`,
        }}
      >
        <div ref={ref}>
          <div className="pt-6">
            {/* <PortableText
              value={content}
              components={blogpostPortableText as any}
            /> */}

            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowMoreItem;
