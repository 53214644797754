import React from 'react';

const Prefooter = () => {
  return (
    <div className="lg:py-15 flex items-center justify-center bg-hi-60 py-12 md:py-14 xl:py-16">
      <div className="mx-auto h-full w-full max-w-[1280px]">
        <div className="hallucination w-full px-4">
          <div className="hal-row">
            <span className="rotate1 time1">L</span>
            <span className="rotate2 time2">L</span>
            <span className="rotate3 time3">M</span>
            <span className="rotate4 time4">H</span>
            <span className="rotate5 time5">A</span>
            <span className="rotate6 time6">L</span>
            <span className="rotate7 time7">L</span>
            <span className="rotate8 time8">U</span>
            <span className="rotate9 time9">C</span>
            <span className="rotate10 time10">I</span>
            <span className="rotate2 time2">N</span>
            <span className="rotate3 time3">A</span>
            <span className="rotate4 time5">T</span>
            <span className="rotate5 time2">I</span>
            <span className="rotate6 time10">O</span>
            <span className="rotate8 time8">N</span>
            <span className="rotate1 time9">I</span>
            <span className="rotate3 time7">N</span>
            <span className="rotate7 time6">D</span>
            <span className="rotate2 time5">E</span>
            <span className="rotate3 time4">X</span>
            <span className="rotate8 time2">L</span>
            <span className="rotate9 time3">L</span>
            <span className="rotate10 time1">M</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prefooter;
